import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { objectToCamelCase } from '@thinknimble/tn-utils'

import AxiosClient from '../AxiosClient'
import { apiErrorHandler } from '../api'

// NOTE: The AxiosClient is congfigured to include '/api' in the baseUrl
const CONTACTS_ENDPOINT = '/contacts/'

export default class ContactAPI extends ModelAPI {
  get client() {
    return AxiosClient
  }

  static ENDPOINT = CONTACTS_ENDPOINT

  static FILTERS_MAP = {
    ...ContactAPI.FILTERS_MAP,
    // filters
    fullName: ApiFilter.create({ key: 'full_name' }),
    hasPitchbookPersonId: ApiFilter.create({ key: 'has_pb_person_id' }),
    isCeoOfPortfolioOrProspectiveCompany: ApiFilter.create({
      key: 'is_ceo_of_portfolio_or_prospective_company',
    }),
  }

  getInvestorsCSV = () => {
    const ENDPOINT = CONTACTS_ENDPOINT + 'investors/csv/'

    return AxiosClient.get(ENDPOINT)
      .then((r) => r.data)
      .catch(apiErrorHandler({ apiName: 'ContactAPI.getInvestorsCSV' }))
  }

  getInvestorsGoogleSpreadsheet = () => {
    const ENDPOINT = CONTACTS_ENDPOINT + 'investors/google-spreadsheet/'

    return AxiosClient.get(ENDPOINT)
      .then((r) => objectToCamelCase(r.data))
      .catch(apiErrorHandler({ apiName: 'ContactAPI.requestResultsAsGoogleSpreadsheet' }))
  }
}
