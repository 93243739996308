export const COMPANY_ONE_PAGER_PAGEVIEW = 'COMPANY_ONE_PAGER__PAGEVIEW'
export const COMPANY_ONE_PAGER_SEARCH_BY_FOUNDER = 'COMPANY_ONE_PAGER__SEARCH_BY_FOUNDER'
export const COMPANY_ONE_PAGER_SEARCH_BY_COMPANY = 'COMPANY_ONE_PAGER__SEARCH_BY_COMPANY'
export const COMPANY_ONE_PAGER_SEARCH_BY_DESCRIPTION = 'COMPANY_ONE_PAGER__SEARCH_BY_DESCRIPTION'
export const COMPANY_ONE_PAGER_CREATE_NOTION_PAGE = 'COMPANY_ONE_PAGER__CREATE_NOTION_PAGE'

export const INVESTOR_SEARCH_PAGEVIEW = 'INVESTOR_SEARCH__PAGEVIEW'
export const INVESTOR_SEARCH_EXPORT = 'INVESTOR_SEARCH__EXPORT'
export const INVESTOR_SEARCH_SELECT_COMPANY = 'INVESTOR_SEARCH__SELECT_COMPANY'
export const INVESTOR_SEARCH_SKIP_TO_TAGS = 'INVESTOR_SEARCH__SKIP_TO_TAGS'
export const INVESTOR_SEARCH_RESULTS_VIEW = 'INVESTOR_SEARCH__RESULTS_VIEW'
export const INVESTOR_SEARCH_SELECT_FILTER = 'INVESTOR_SEARCH__SELECT_FILTER'
export const INVESTOR_SEARCH_VIEW_INVESTMENTS = 'INVESTOR_SEARCH__VIEW_INVESTMENTS'
export const INVESTOR_SEARCH_VIEW_GPS = 'INVESTOR_SEARCH__VIEW_GPS'
export const INVESTOR_SEARCH_ASYNC_PENDING_VIEW = 'INVESTOR_SEARCH__ASYNC_PENDING_VIEW'
export const INVESTOR_SEARCH_ASYNC_PENDING_NEW_SEARCH = 'INVESTOR_SEARCH__ASYNC_PENDING_NEW_SEARCH'

export const events = {
  COMPANY_ONE_PAGER_PAGEVIEW,
  COMPANY_ONE_PAGER_SEARCH_BY_FOUNDER,
  COMPANY_ONE_PAGER_SEARCH_BY_COMPANY,
  COMPANY_ONE_PAGER_CREATE_NOTION_PAGE,
  COMPANY_ONE_PAGER_SEARCH_BY_DESCRIPTION,
  INVESTOR_SEARCH_PAGEVIEW,
  INVESTOR_SEARCH_EXPORT,
  INVESTOR_SEARCH_SELECT_COMPANY,
  INVESTOR_SEARCH_SKIP_TO_TAGS,
  INVESTOR_SEARCH_RESULTS_VIEW,
  INVESTOR_SEARCH_SELECT_FILTER,
  INVESTOR_SEARCH_VIEW_INVESTMENTS,
  INVESTOR_SEARCH_VIEW_GPS,
  INVESTOR_SEARCH_ASYNC_PENDING_VIEW,
  INVESTOR_SEARCH_ASYNC_PENDING_NEW_SEARCH,
}
