<template>
  <ol class="flex items-center w-full space-x-20 justify-center">
    <InvestorSearchHorizontalStepperStep
      sublabel="Find company"
      :step="1"
      :active="step === 1"
      :completed="step > 1"
      @click.prevent="step > 1 && $emit('select', 1)"
    >
      Company Search
    </InvestorSearchHorizontalStepperStep>
    <InvestorSearchHorizontalStepperStep
      sublabel="Choose focus"
      :step="2"
      :active="step === 2"
      :completed="step > 2"
      @click.prevent="step > 2 && $emit('select', 2)"
    >
      Tag Selection
    </InvestorSearchHorizontalStepperStep>
    <InvestorSearchHorizontalStepperStep
      sublabel="Tabular format"
      :step="3"
      :active="step === 3"
      :completed="step > 3"
    >
      Results
    </InvestorSearchHorizontalStepperStep>
  </ol>
</template>

<script>
import InvestorSearchHorizontalStepperStep from '@/components/InvestorSearch/InvestorSearchHorizontalStepperStep'

export default {
  components: { InvestorSearchHorizontalStepperStep },
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
}
</script>
