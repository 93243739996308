import Model, { fields } from '@thinknimble/tn-models'
import Deal from '@/services/deals/models'
import Contact from '@/services/contacts/models'

export default class DealInvestor extends Model {
  static id = new fields.CharField({ readOnly: true })
  static investor = new fields.ModelField({
    ModelClass: () => import('@/services/companies/models').then((module) => module.default),
  })
  static deal = new fields.ModelField({ ModelClass: Deal })
  static leadOrSoleInvestor = new fields.BooleanField({ readOnly: true })
  static investmentAmount = new fields.CharField({ readOnly: true })
  static gp = new fields.ModelField({ ModelClass: Contact })

  get formattedInvestmentAmount() {
    return this.investmentAmount ? `$${this.investmentAmount}` : 'N/A'
  }
}
