import Model, { fields } from '@thinknimble/tn-models'
import moment from 'moment'

export default class Deal extends Model {
  static id = new fields.CharField({ readOnly: true })
  static industryNames = new fields.ArrayField({ type: new fields.CharField({ readOnly: true }) })
  static dealId = new fields.CharField({ readOnly: true })
  static companyName = new fields.CharField({ readOnly: true })
  static companyDescription = new fields.CharField({ readOnly: true })
  static companySalesforceUrl = new fields.CharField({ readOnly: true })
  static dealType = new fields.CharField({ readOnly: true })
  static dealStage = new fields.CharField({ readOnly: true })
  static dealDate = new fields.CharField({ readOnly: true })
  static dealNumber = new fields.IntegerField({ readOnly: true })
  static dealSize = new fields.CharField({ readOnly: true })

  get formattedDealDate() {
    return this.dealDate ? moment(this.dealDate).format('YYYY-MM-DD') : 'N/A'
  }

  get formattedDealSize() {
    return this.dealSize ? `$${this.dealSize}` : 'N/A'
  }
}
