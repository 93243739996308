import Model, { fields } from '@thinknimble/tn-models'

import IndustryAPI from './api'

export default class Industry extends Model {
  static api = IndustryAPI.create(Industry)

  static id = new fields.CharField({ readOnly: true })
  static name = new fields.CharField({ readOnly: true })
}
