import Model, { fields } from '@thinknimble/tn-models'
import CompanyIndustryAPI from './api'

export default class CompanyIndustry extends Model {
  static api = CompanyIndustryAPI.create(CompanyIndustry)

  static id = new fields.CharField({ readOnly: true })
  static industryId = new fields.CharField({ readOnly: true })
  static companyId = new fields.CharField({ readOnly: true })
}
