import { ModelAPI } from '@thinknimble/tn-models'
import { objectToSnakeCase } from '@thinknimble/tn-utils'

import AxiosClient from '../AxiosClient'
import { apiErrorHandler } from '../api'

// NOTE: The AxiosClient is congfigured to include '/api' in the baseUrl
const TRACKED_EVENT_ENDPOINT = '/tracked-events/'

export default class TrackedEventAPI extends ModelAPI {
  get client() {
    return AxiosClient
  }

  static ENDPOINT = TRACKED_EVENT_ENDPOINT

  create(user, event, context = {}) {
    const data = { user, event, context: objectToSnakeCase(context) }
    return this.client
      .post(TRACKED_EVENT_ENDPOINT, data)
      .then((response) => this.cls.fromAPI(response.data))
      .catch(apiErrorHandler({ apiName: 'TrackedEventAPI.create' }))
  }
}
