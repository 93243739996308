<template>
  <li
    class="flex items-center space-x-2.5"
    :class="{
      'text-green-700 cursor-pointer': completed,
      'text-blue-600 cursor-not-allowed': active,
      'text-gray-500 cursor-not-allowed': !completed && !active,
    }"
  >
    <span
      v-if="completed"
      class="flex items-center justify-center w-8 h-8 bg-green-50 border border-green-300 rounded-full shrink-0"
    >
      <svg
        class="w-3.5 h-3.5 text-green-700"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 16 12"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 5.917 5.724 10.5 15 1.5"
        />
      </svg>
    </span>
    <span
      v-else
      class="flex items-center justify-center w-8 h-8 border rounded-full shrink-0"
      :class="{
        'bg-blue-100 border-blue-300': active,
        'border-gray-500': !active,
      }"
    >
      {{ step }}
    </span>
    <span>
      <h3 class="font-medium leading-tight"><slot /></h3>
      <p v-if="sublabel" class="text-sm">{{ sublabel }}</p>
    </span>
  </li>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    sublabel: {
      type: String,
    },
  },
}
</script>
