import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'

import AxiosClient from '../AxiosClient'

// NOTE: The AxiosClient is congfigured to include '/api' in the baseUrl
const INDUSTRIES_ENDPOINT = '/industries/'

export default class IndustryAPI extends ModelAPI {
  get client() {
    return AxiosClient
  }

  static ENDPOINT = INDUSTRIES_ENDPOINT

  static FILTERS_MAP = {
    ...IndustryAPI.FILTERS_MAP,
    // filters
    nameIContains: ApiFilter.create({ key: 'name__icontains' }),
  }
}
