import Model, { fields } from '@thinknimble/tn-models'

import TrackedEventAPI from './api'
import User from '@/services/users'

export default class TrackedEvent extends Model {
  static api = TrackedEventAPI.create(TrackedEvent)

  static id = new fields.CharField({ readOnly: true })
  static datetimeCreated = new fields.CharField({ readOnly: true })
  static event = new fields.CharField({ readOnly: true })
  static user = new fields.ModelField({ ModelClass: User })
}
