import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'

import AxiosClient from '../AxiosClient'

// NOTE: The AxiosClient is congfigured to include '/api' in the baseUrl
const COMPANY_INDUSTRIES_ENDPOINT = '/company-industries/'

export default class CompanyIndustryAPI extends ModelAPI {
  get client() {
    return AxiosClient
  }

  static ENDPOINT = COMPANY_INDUSTRIES_ENDPOINT

  static FILTERS_MAP = {
    ...CompanyIndustryAPI.FILTERS_MAP,
    // filters
    companyId: ApiFilter.create({ key: 'company_id' }),
  }
}
