import Model, { fields } from '@thinknimble/tn-models'

import ContactAPI from './api'

export default class Contact extends Model {
  static api = ContactAPI.create(Contact)

  static id = new fields.CharField({ readOnly: true })
  static email = new fields.CharField({ readOnly: true })
  static firstName = new fields.CharField({ readOnly: true })
  static lastName = new fields.CharField({ readOnly: true })
  static fullName = new fields.CharField({ readOnly: true })
  static title = new fields.CharField({ readOnly: true })
  static linkedinUrl = new fields.CharField()
  static salesforceUrl = new fields.CharField()
  static sigparserMailboxesList = new fields.ArrayField({ type: new fields.CharField() })
  static communityTypesList = new fields.ArrayField({ type: new fields.CharField() })
  static aboutLinkedin = new fields.CharField()
  static companyName = new fields.CharField()
  static sigparserLastOutbound = new fields.CharField({ readOnly: true })
  static sigparserLastInbound = new fields.CharField({ readOnly: true })
  static warmth = new fields.CharField({ readOnly: true })
  static sigparserClosestInternalEmail = new fields.CharField({ readOnly: true })
}
